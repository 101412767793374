import React from 'react';
import { Link } from "react-router-dom";

function Home() {
    return (
        <div style={{"padding":"10px"}}>
            <h1>ScienceKat's homepage!</h1>
            <p>hello i am sciencekat or scikat</p>
            <p>i make things sometimes</p>
            <p>i know a little about coding</p>
            <p>i own <Link to="#">sciencekat.com</Link> as well, you should check it out.</p>
        </div>
    );
}

export default Home;
