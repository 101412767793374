import React, {useState} from "react";
import styles from "../Scorer.module.css";

function Scorer() {
    const unstackables: Array<string> = [
        "Air",
        "Antennae",
        "Bulb",
        "Bur",
        "Clover",
        "Compass",
        "Cutter",
        "M Cutter*",
        "Disc",
        "Honey",
        "Iris",
        "Magnet",
        "Moon",
        "Pincer",
        "Powder",
        "Relic",
        "Salt",
        "Shovel",
        "Sponge",
        "M Shovel*",
        "Third Eye",
        "MTE*"
    ]

    const scores: Array<[string, number]> = [
        ["Air", 1],
        ["Amulet", 2],
        ["Ankh", 1],
        ["Antennae", 2],
        ["Basic", 5],
        ["Basil", 3],
        ["Battery", 4],
        ["Bone", 2],
        ["Bubble", 4],
        ["Bulb", 1],
        ["Bur", 4],
        ["Cactus", 2],
        ["Card", 2],
        ["Carrot", 2],
        ["Chip", 4],
        ["Claw", 3],
        ["Clover", 1],
        ["Coin", 1],
        ["Compass", 2],
        ["Corn", 3],
        ["Corruption", 1],
        ["Cotton", 2],
        ["Cutter", 5],
        ["Dahlia", 2],
        ["Dandelion", 1],
        ["Dice", 4],
        ["Disc", 3],
        ["BeetleEgg", 4],
        ["AntEgg", 4],
        ["Fangs", 3],
        ["Faster", 1],
        ["Glass", 2],
        ["Grapes", 2],
        ["Heavy", 2],
        ["Honey", 2],
        ["Iris", 4],
        ["Jelly", 1],
        ["Leaf", 3],
        ["Light", 2],
        ["Lightning", 3],
        ["Lotus", 2],
        ["Magnet", 4],
        ["Mark", 2],
        ["Missile", 3],
        ["Moon", 4],
        ["Orange", 2],
        ["Pearl", 2],
        ["Peas", 2],
        ["Pincer", 3],
        ["Plank", 1],
        ["Pollen", 2],
        ["Poo", 1],
        ["Powder", 3],
        ["Privet", 1],
        ["Relic", 2],
        ["Rice", 4],
        ["Rock", 3],
        ["Root", 1],
        ["Rose", 3],
        ["Rubber", 5],
        ["Salt", 1],
        ["Sand", 4],
        ["Shell", 2],
        ["Shovel", 5],
        ["Soil", 5],
        ["Sponge", 4],
        ["Starfish", 3],
        ["Stick", 4],
        ["Binger", 3],
        ["Stinger", 3],
        ["Talisman", 1],
        ["ThirdEye", 5],
        ["Tomato", 2],
        ["Uranium", 5],
        ["Web", 2],
        ["Wing", 3],
        ["Yggdrasil", 5],
        ["YinYang", 1],
        ["Yucca", 2],
        ["Square", 5]
    ];

    const scoresMap = new Map(scores);

    const displayPetalField = (item: [string, number], index: number) => {
        return (
            <tr className={styles.petal} id={item[0]} key={item[0]}>
                <td>
                    <label htmlFor={"Ultra"+item[0]}>{item[0]}: </label>
                </td>
                <td>
                    {item[1] === 5 ? <input type="number" name={"Mythic"+item[0]} placeholder="Mythic" onChange={calculateFinalScore} className={styles.mythic} /> : ""}
                </td>
                <td>
                    <input type="number" name={"Ultra"+item[0]} placeholder="Ultra" onChange={calculateFinalScore} className={styles.ultra} />
                </td>
                <td>
                    <input type="number" name={"Super"+item[0]} placeholder="Super" onChange={calculateFinalScore} className={styles.super} />
                </td>
            </tr>
        )
    }

    const calculateFinalScore = () => {
        let total = 0;
        const elems = document.getElementsByClassName(styles.petal);
        for(let i = 0; i < elems.length; i++) {
            let elem = elems[i];
            let petalCounter = unstackables.includes(elem.id) ? 9 : 0;
            let petalValue = scoresMap.get(elem.id);
            let truePetalValue = (typeof petalValue == 'undefined' ? 0 : petalValue);
            let inputs = elems[i].getElementsByTagName("input");
            for(let x = inputs.length - 1; x >= 0; x--) {
                if(inputs[x].name.startsWith("Mythic")) {
                    let inputValue = isNaN(Number.parseInt(inputs[x].value)) ? 0 : Number.parseInt(inputs[x].value);
                    total += inputValue;
                    petalCounter += inputValue;
                } else if(inputs[x].name.startsWith("Ultra")) {
                    let inputValue = isNaN(Number.parseInt(inputs[x].value)) ? 0 : Number.parseInt(inputs[x].value);
                    total += petalCounter + inputValue > 10 ? (10 - petalCounter) * truePetalValue + inputValue - 10 + petalCounter : inputValue * truePetalValue;
                    petalCounter += inputValue;
                } else if(inputs[x].name.startsWith("Super")) {
                    let inputValue = isNaN(Number.parseInt(inputs[x].value)) ? 0 : Number.parseInt(inputs[x].value);
                    total += petalCounter + inputValue > 10 ? (10 - petalCounter) * truePetalValue * 50 + (inputValue - 10 + petalCounter) * 50 : inputValue * truePetalValue * 50;
                    petalCounter += inputValue;
                }
            }
        }
        setFinalScore(total)
    }

    let [finalScore, setFinalScore] = useState(0);

    return (
        <>
            <h1>Score: {finalScore}</h1>
            <table className={styles.myTable}>
                <tbody>
                    {scores.map(displayPetalField)}
                </tbody>
            </table>
        </>
    )
}

export default Scorer;